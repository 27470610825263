/* eslint-disable react/jsx-props-no-spreading */
import ImageComponent from '@/components/Layout/Image';
import Link from 'next/link';
import { forwardRef } from 'react';
import classNames from '@/utils/className';

interface MemberCardProps {
  name: string;
  role: string;
  url?: string;
  image: {
    url: string;
    blurhash: string;
  };
}

interface MemberListingProps {
  members: MemberCardProps[];
}

const MemberCard = forwardRef<HTMLAnchorElement, MemberCardProps>(({ name, url = '', role, image }, ref) => {
  const linkProps = {};
  const Tag = url ? 'a' : 'div';

  if (url) Object.assign(linkProps, { href: url, ref });

  return (
    <Tag {...linkProps} className={classNames('flex gap-4', url && 'cursor-pointer')} title={`${url && 'Ver página de '}${name}`}>
      <div className="h-20 w-20 [&>figure>div>img]:rounded-half [&>figure>div>canvas]:rounded-half">
        <ImageComponent src={image.url} blurhash={image.blurhash} alt={`${name} - ${role} no Canaltech`} title={`${name} - ${role} no Canaltech`} />
      </div>
      <div className="flex flex-col content-center justify-center gap-1">
        <p className="font-medium text-mglBlue400">{name}</p>
        <p className="text-sm text-mglGray900">{role}</p>
      </div>
    </Tag>
  );
});

export default function MemberListing({ members }: MemberListingProps) {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-8 pt-10 tablet:grid-cols-2 desktop:grid-cols-3">
      {members.map(member =>
        member.url ? (
          <Link key={member.name} href={member.url} prefetch={false} passHref>
            <MemberCard name={member.name} role={member.role} image={member.image} url={member.url} />
          </Link>
        ) : (
          <MemberCard key={member.name} name={member.name} role={member.role} image={member.image} />
        )
      )}
    </div>
  );
}
