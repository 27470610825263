import WrapperContent from '@/components/Layout/WrapperContent';

export default function Footer() {
  return (
    <WrapperContent paddingOnBottom>
      <p className="w-full text-balance text-center text-sm">
        UNILOGIC Media Group LTDA.- CNPJ 09.081.439/0001-60 | Endereço: Rua Formosa, 79 - Rudge Ramos, São Bernardo do Campo/SP. CEP: 09.626-060. Canaltech -
        Todos os direitos reservados. Endereço eletrônico:{' '}
        <a href="https://canaltech.com.br/" className=" text-mglBlue400 no-underline" target="_blank" rel="noreferrer">
          canaltech.com.br
        </a>{' '}
        | Fale conosco:{' '}
        <a href="https://canaltech.com.br/sobre/" className="text-mglBlue400 no-underline" target="_blank" rel="noreferrer">
          canaltech.com.br/sobre/
        </a>
      </p>
    </WrapperContent>
  );
}
