import HTMLReactParser from 'html-react-parser';
import HeaderImage from '@/components/Internals/HeaderImage';
import WrapperContent from '@/components/Layout/WrapperContent';
import { DivContentNews } from '@/components/Internals/NewsContent/styles';
import { GridInternal } from '@/components/Internals/NewsItem/styles';
import Titulo from '@/components/Layout/Title';
import { AboutPageProps } from '@/pages/sobre';
import * as S from '../AdvertiseHere/styles';
import ContactBox from '../About/ContactBox';
import MemberListing from '../Team/MemberListing';
import { mglBlue, mglGray } from '@/styles/designSystemColors';

interface InstitutionalContentProps {
  text: string;
  headerImg: { url: string; srcset: { [key: number]: string }; blurhash: string };
  hasContactForm?: boolean;
  members?: AboutPageProps['institutionalData']['data']['equipe'];
}

export default function InstitutionalContent({ text, headerImg, hasContactForm = false, members = undefined }: InstitutionalContentProps) {
  return (
    <>
      <WrapperContent isOnHeader paddingOnBottom>
        <GridInternal hasMarginTop singleColumn>
          <S.AdvertiseHereMainContainer singleColumn>
            <S.AdvertiseHerePageTextContainer hasNoSideContent>
              <HeaderImage url={headerImg.url} caption="" srcset={headerImg.srcset} isHeader="full" />
              <DivContentNews>{HTMLReactParser(text)}</DivContentNews>
            </S.AdvertiseHerePageTextContainer>
          </S.AdvertiseHereMainContainer>
        </GridInternal>
      </WrapperContent>
      {hasContactForm && (
        <WrapperContent backgroundColor={mglBlue.mglBlue400} paddingOnBottom>
          <ContactBox />
        </WrapperContent>
      )}
      {hasContactForm && (
        <WrapperContent backgroundColor={mglGray.mglGray100}>
          <Titulo texto="Conheça Nossa Equipe" color="black" noPadding />
          <MemberListing members={members.map(member => ({ name: member.nome, role: member.cargo, image: member.imagem, url: member.url }))} />
        </WrapperContent>
      )}
    </>
  );
}
