import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  AdvertiseHereMainContainer: {
    singleColumn: {
      true: '[grid-column:1]',
      false: '',
    },
  },
  AdvertiseHerePageTextContainer: {
    hasNoSideContent: {
      true: 'first-of-type:[&>div]:aspect-[initial]',
      false: '',
    },
  },
} as const;

export const AdvertiseHereMainContainer = ({ children, singleColumn = undefined, ...rest }: ComponentPropsWithoutRef<'div'> & { singleColumn?: boolean }) => {
  return (
    <div
      className={classNames(
        'font-primary [grid-column-start:1] [grid-column:1] desktop:[grid-column:initial]',
        singleColumn != null && variants.AdvertiseHereMainContainer.singleColumn[singleColumn.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const AdvertiseHerePageTextContainer = ({
  children,
  hasNoSideContent = undefined,
  ...rest
}: ComponentPropsWithoutRef<'div'> & { hasNoSideContent?: boolean }) => {
  return (
    <div
      className={classNames(
        'w-full pr-0 text-lg tablet:pr-[initial] [&>div>div]:mb-16 first-of-type:[&>div]:aspect-[540/306] [&>h2]:mb-8 [&>h2]:text-2xl [&>h2]:font-medium [&>p]:mb-8 [&>p]:text-lg [&>ul>li]:list-none before:[&>ul>li]:mr-2 before:[&>ul>li]:content-[•]',
        hasNoSideContent != null && variants.AdvertiseHerePageTextContainer.hasNoSideContent[hasNoSideContent.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const AdvertiseHereChipContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className="max-w-80 rounded-[60px] [&>div>div>span]:text-base [&>div>div>span]:leading-4 [&>div>div]:[margin-inline:auto] [&>div]:flex [&>div]:px-8 [&>div]:py-6 [&>span]:m-0"
      {...rest}>
      {children}
    </div>
  );
};

export const AdvertiseHereGridItem = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className="flex w-full flex-col items-center justify-center rounded bg-mglGray50 px-0 py-4 shadow-[0px_10px_35px_-10px_rgba(64,_59,_75,_0.1)] [&>svg]:text-2xl"
      {...rest}>
      {children}
    </div>
  );
};

export const AdvertiseHereMainText = ({ children, ...rest }: ComponentPropsWithoutRef<'h4'>) => {
  return (
    <h4 className="mb-1 mt-2 text-center text-lg font-medium text-mglGray900" {...rest}>
      {children}
    </h4>
  );
};

export const AdvertiseHereSubText = ({ children, ...rest }: ComponentPropsWithoutRef<'p'>) => {
  return (
    <p className="text-center text-xs font-light text-mglGray900" {...rest}>
      {children}
    </p>
  );
};

export default {};
