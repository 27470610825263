import Breadcrumb, { BreadcrumbItem } from '@/components/Layout/Breadcrumb';
import InternalTitle from '@/components/Layout/InternalTitle';
import WrapperContent from '@/components/Layout/WrapperContent';

export interface HeaderProps {
  breadcrumb: BreadcrumbItem[];
  titulo: string;
}

export default function InstitutionalHeader({ breadcrumb, titulo }: HeaderProps) {
  return (
    <WrapperContent isOnHeader hasBreadcrumb>
      <header className="flex flex-col justify-between tablet:flex-row tablet:items-center">
        <Breadcrumb breadcrumbList={breadcrumb} />
      </header>
      <InternalTitle title={titulo} />
    </WrapperContent>
  );
}
