/* eslint-disable dot-notation */
import { Dispatch, FormEvent, SetStateAction, useEffect, useReducer, useState } from 'react';
import Modal from '@/components/Layout/Modal';
import Chip from '@/components/Layout/Chip';
import useAmpComponent from '@/hooks/useAmpComponent';
import axios from 'axios';
import * as S from '../../AdvertiseHere/FormModal/styles';
import { mglBlue } from '@/styles/designSystemColors';

interface ContactFormProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const subjectSelectOptions = ['Dúvidas', 'Elogios, críticas e sugestões', 'Erros no site', 'Privacidade', 'Outros assuntos', 'Sugestões de pautas'];

const whereSelectOptions = [
  'Amigo',
  'Facebook',
  'Instagram',
  'Twitter',
  'Telas Corporativas',
  'TV a Cabo',
  'Outros sites',
  'Busca no Google',
  'Colunistas Canaltech',
  'Publicidade',
  'Outros',
];

const initialValues = {
  nome: '',
  email: '',
  telefone: '',
  comoConheceu: '',
  assunto: '',
  mensagem: '',
};

const feedbackMessage = {
  title: {
    success: 'Dados enviados com sucesso',
    error: 'Encontramos um problema ao enviar os seus dados',
  },
  body: {
    success: 'Agradecemos pela sua mensagem!',
    error: 'Por favor, tente novamente mais tarde.',
  },
};

type reducerChangeActionType = {
  type: 'change';
  fieldName: keyof typeof initialValues;
  value: string;
};

type reducerResetActionType = {
  type: 'reset';
};

type submissionStatusType =
  | {
      hasSubmitted: false;
    }
  | {
      hasSubmitted: true;
      status: 'success' | 'error';
    };

export default function ContactForm({ isOpen, setIsOpen }: ContactFormProps) {
  const { Icon } = useAmpComponent();

  const [isWhereSelectMenuOpen, setIsWhereSelectMenuOpen] = useState(false);
  const [isSubjectSelectMenuOpen, setIsSubjectSelectMenuOpen] = useState(false);

  function reducer(state: typeof initialValues, action: reducerChangeActionType | reducerResetActionType) {
    if (action.type === 'change') {
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    }

    if (action.type === 'reset') {
      Object.keys(state).map(key => ({
        [key]: '',
      }));
    }

    return state;
  }

  const [state, dispatch] = useReducer(reducer, initialValues);

  const { nome, email, telefone, comoConheceu, mensagem, assunto } = state;

  const [submissionStatus, setSubmissionStatus] = useState<submissionStatusType>({ hasSubmitted: false });

  const handleFormChange = event => {
    const { name, value } = event.target;
    dispatch({ type: 'change', fieldName: name, value });
  };

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(state).map(key => data.append(key, state[key]));
    axios
      .post<{ code: 200 | 400 | 500; errors: string[]; result: string }>('/contato.json', data)
      .then(res => {
        if (res.data.code === 200) {
          setSubmissionStatus({ hasSubmitted: true, status: 'success' });
        } else {
          setSubmissionStatus({ hasSubmitted: true, status: 'error' });
        }
      })
      .catch(() => {
        setSubmissionStatus({ hasSubmitted: true, status: 'error' });
      });
    dispatch({ type: 'reset' });
  };

  const phoneMask = (phone: string) => {
    const maskedNumber = phone
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
    dispatch({ type: 'change', fieldName: 'telefone', value: maskedNumber });
    return maskedNumber;
  };

  const isFormValid = () => !!nome && !!email && !!telefone && !!comoConheceu && !!assunto;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {!submissionStatus.hasSubmitted ? (
        <S.FormModalFormComponent id="form-contato" encType="multipart/form-data">
          <S.FormModalFieldBox>
            <S.FormModalFieldName>
              <Icon icon="mdi:message-text-outline" color={mglBlue.mglBlue400} />
              <S.FormModalFieldLabel htmlFor="comoConheceu">selecione um assunto</S.FormModalFieldLabel>
            </S.FormModalFieldName>

            <S.FormModalSelect>
              <S.FormModalSelectMenu
                data-testid="assunto"
                isOpen={isSubjectSelectMenuOpen}
                onClick={() => setIsSubjectSelectMenuOpen(!isSubjectSelectMenuOpen)}>
                <S.FormModalSelectText>{assunto}&nbsp;</S.FormModalSelectText>
              </S.FormModalSelectMenu>
              <S.FormModalSelectOptions isOpen={isSubjectSelectMenuOpen}>
                {subjectSelectOptions.map(option => (
                  <S.FormModalSelectLabel
                    key={option}
                    htmlFor={option}
                    onClick={() => {
                      dispatch({ type: 'change', fieldName: 'assunto', value: option });
                      setIsSubjectSelectMenuOpen(false);
                    }}>
                    {option}
                    <S.FormModalSelectInput id={option} type="radio" name="assunto" value={option} />
                  </S.FormModalSelectLabel>
                ))}
              </S.FormModalSelectOptions>
            </S.FormModalSelect>
          </S.FormModalFieldBox>

          <S.FormModalFieldBox>
            <S.FormModalFieldName>
              <Icon icon="mdi:person-outline" color={mglBlue.mglBlue400} />
              <S.FormModalFieldLabel htmlFor="nome">nome</S.FormModalFieldLabel>
            </S.FormModalFieldName>
            <S.FormModalTextInput
              type="text"
              id="nome"
              name="nome"
              minLength={2}
              value={nome}
              onChange={handleFormChange}
              onKeyPress={event => event.key === 'Enter' && handleSubmit}
              required
            />
          </S.FormModalFieldBox>

          <S.FormModalFieldBox>
            <S.FormModalFieldName>
              <Icon icon="mdi:email-outline" color={mglBlue.mglBlue400} />
              <S.FormModalFieldLabel htmlFor="email">e-mail</S.FormModalFieldLabel>
            </S.FormModalFieldName>
            <S.FormModalTextInput
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleFormChange}
              onKeyPress={event => event.key === 'Enter' && handleSubmit}
              required
            />
          </S.FormModalFieldBox>

          <S.FormModalFieldBox>
            <S.FormModalFieldName>
              <Icon icon="mdi:phone-outline" color={mglBlue.mglBlue400} />
              <S.FormModalFieldLabel htmlFor="telefone">telefone</S.FormModalFieldLabel>
            </S.FormModalFieldName>
            <S.FormModalTextInput
              type="text"
              id="telefone"
              name="telefone"
              minLength={14}
              maxLength={15}
              placeholder="(00) 0000-0000"
              onKeyUp={() => phoneMask(telefone)}
              value={telefone}
              onChange={handleFormChange}
              onKeyPress={event => event.key === 'Enter' && handleSubmit}
              required
            />
          </S.FormModalFieldBox>

          <S.FormModalFieldBox>
            <S.FormModalFieldName>
              <Icon icon="mdi:map-marker-question" color={mglBlue.mglBlue400} />
              <S.FormModalFieldLabel htmlFor="comoConheceu">onde conheceu o Canaltech?</S.FormModalFieldLabel>
            </S.FormModalFieldName>
            <S.FormModalSelect>
              <S.FormModalSelectMenu data-testid="comoConheceu" isOpen={isWhereSelectMenuOpen} onClick={() => setIsWhereSelectMenuOpen(!isWhereSelectMenuOpen)}>
                <S.FormModalSelectText>{comoConheceu}&nbsp;</S.FormModalSelectText>
              </S.FormModalSelectMenu>
              <S.FormModalSelectOptions isOpen={isWhereSelectMenuOpen}>
                {whereSelectOptions.map(option => (
                  <S.FormModalSelectLabel
                    key={option}
                    htmlFor={option}
                    onClick={() => {
                      dispatch({ type: 'change', fieldName: 'comoConheceu', value: option });
                      setIsWhereSelectMenuOpen(false);
                    }}>
                    {option}
                    <S.FormModalSelectInput id={option} type="radio" name="comoConheceu" value={option} />
                  </S.FormModalSelectLabel>
                ))}
              </S.FormModalSelectOptions>
            </S.FormModalSelect>
          </S.FormModalFieldBox>

          <S.FormModalFieldBox>
            <S.FormModalFieldName>
              <Icon icon="mdi:text-box-edit-outline" color={mglBlue.mglBlue400} />
              <S.FormModalFieldLabel htmlFor="mensagem">mensagem</S.FormModalFieldLabel>
            </S.FormModalFieldName>
            <S.FormModalTextArea
              name="mensagem"
              id="mensagem"
              value={mensagem}
              onChange={handleFormChange}
              onKeyPress={event => event.key === 'Enter' && handleSubmit}
            />
          </S.FormModalFieldBox>

          <S.FormModalSubmitButton type="button" onClick={handleSubmit} disabled={!isFormValid()}>
            <Chip
              title="enviar mensagem"
              texto="enviar mensagem"
              chipSize="large"
              borderColor="black"
              color="black"
              backgroundColor="white"
              posIcon="chevron-right"
            />
          </S.FormModalSubmitButton>
        </S.FormModalFormComponent>
      ) : (
        <S.FormModalSuccessMessageContainer>
          <S.FormModalSuccessMessage isTitle>{feedbackMessage['title'][submissionStatus.status]}</S.FormModalSuccessMessage>

          <S.FormModalSuccessMessage>{feedbackMessage['body'][submissionStatus.status]}</S.FormModalSuccessMessage>
        </S.FormModalSuccessMessageContainer>
      )}
    </Modal>
  );
}
